<template>
    <div class="social">
        <v-btn
            v-if="canShare"
            outlined
            color="#0076a7"
            class="social__btn"
            role="button"
            @click="shareApi()"
        >
            share
            <v-icon class="social__icon">
                $vuetify.icons.share
            </v-icon>
        </v-btn>
        <v-btn
            outlined
            color="#1877f2"
            class="social__btn"
            role="button"
            @click="shareFacebook()"
        >
            <template v-if="!canShare">share</template>
            <v-icon class="social__icon">
                $vuetify.icons.facebook
            </v-icon>
        </v-btn>
    </div>
</template>

<script>
export default {
    name: 'Social',
    data() {
        return {
            shareData: {
                title: this.meta.title,
                text: `${this.meta.description} | ${window.location.href}`,
                url: window.location.href
            }
        }
    },
	props: {
		meta: {
			type: Object,
			required: true
		}
	},
    computed: {
        canShare() {
            return navigator.share && navigator.canShare(this.shareData);
        }
    },
    methods: {
        shareApi() {
            navigator.share(this.shareData);
        },
        shareFacebook() {
            window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(document.URL)}&t=${encodeURIComponent(document.URL)}`);
        }
    }
};
</script>

<style scoped lang="scss">
.social {
    display: flex;
    justify-content: center;
    margin: 10px 0;
    &__btn {
        margin: 0 0 0 gutter-size(4);
    }
    &__icon {
        font-size: $font-size-lg;
        margin-left: gutter-size(3);
    }
    @media only screen and (min-width: #{map-get($grid-breakpoints, 'sm')}) {
        justify-content: flex-end;
    }
}
</style>
